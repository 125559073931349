import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
//import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import './App.css';
import './AI.css';

import Nav from './Nav';

function App() {
  
  return (
      <Nav/>
  );
}

export default App;