import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
//import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function AI() {
  const {
    GoogleGenerativeAI,
    HarmCategory,
    HarmBlockThreshold,
  } = require("@google/generative-ai");

  const MODEL_NAME = "gemini-1.0-pro";
  const API_KEY = "AIzaSyAdNfBEeSEYJvmNEbjQewR_DMuD0GS5k2w";

  const [inputValue, setInputValue] = useState('');

  const [chatHistory, setChatHistory] = useState([]);

  const [textChat, setTextChat] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log("Submitted value: ", inputValue);

    const value = inputValue;

    setInputValue('');

    setTextChat(chat => chat + `<div><strong>User:</strong> ${value}<br/></div>`);

    const genAI = new GoogleGenerativeAI(API_KEY);
    const model = genAI.getGenerativeModel({ model: MODEL_NAME });

    const generationConfig = {
      temperature: 0.9,
      topK: 1,
      topP: 1,
      maxOutputTokens: 2048,
    };

    const safetySettings = [
      {
        category: HarmCategory.HARM_CATEGORY_HARASSMENT,
        threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
        threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
        threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
        threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
      },
    ];

    const chat = model.startChat({
      generationConfig,
      safetySettings,
      history: chatHistory
    });

    const result = await chat.sendMessage(value);
    const response = result.response;
    console.log(response.text());

    //Ghi lịch sử
    const newMessageUser = {
      role: "user",
      parts: [{ text: value }],
    };
    setChatHistory(prevHistory => [...prevHistory, newMessageUser]);

    const newMessageModel = {
      role: "model",
      parts: [{ text: response.text() }],
    };
    setChatHistory(prevHistory => [...prevHistory, newMessageModel]);


    //Hiển thị chat
    setTextChat(chat => chat + `<div><strong>Bot:</strong> ${response.text()}<br/></div>`);
  };

  return (
    <>

      <div className='chat-section'>
        <div className='scroll-chat'>
          <Typography className='content-chat' variant="body1" component="div" dangerouslySetInnerHTML={{ __html: textChat }} />
        </div>
        

        <form onSubmit={handleSubmit}>
          <Box className='box-chat'>
            <label>
              <TextField
                id="outlined-basic"
                className='input-chat'
                label="Chat Box"
                variant="outlined"
                value={inputValue}
                onChange={handleInputChange}
              />
            </label>
            {/* <Button variant="contained" type="submit">Send</Button> */}
          </Box>
        </form>
      </div>

    </>
  );
}

export default AI;